import React from "react"
import { Container, Row, Col } from "reactstrap"

import Layout from "../components/Layout"
import PageFooter from "../components/PageFooter"
import SEO from "../components/SEO"
import WipeScreen from "../components/WipeScreen"

import { StaticImage } from "gatsby-plugin-image"

const SecondPage = () => (
  <>
    <SEO title={"PGM | Printilo Calendar"} slug={"printilo"} />
    <Row className="white-container case-study">
      <Container className="mt-5 pt-5">
        <Row className="mt-5 pt-5 no-gutters">
          <Col sm="8" className="super-margin">
            <h1>
              Printilo Calendar - Crafting Custom Calendars with Personalized
              Design
            </h1>
            <br />
            <p>Case Study</p>
          </Col>
        </Row>
      </Container>
    </Row>
    <Row className="case-study-content">
      <Container
        className="hero-container"
        style={{ background: "#fff", marginBottom: 30 }}
        fluid
        tag="section"
      >
        <div className="work-image">
          <StaticImage
            src="../images/calendar/calendar_3.jpg"
            alt="web_1"
            width={1600}
            quality={100}
            formats={["auto", "webp"]}
          />
        </div>
      </Container>

      <Container className="article-content" tag="section" fluid>
        <Container>
          <Row className="inter-section">
            <Col sm="12">
              <Col sm="6" className="p-0 section-text float-left">
                <h3>01 Challenge</h3>
                <p>
                  Printilo Calendar is an innovative online platform that
                  empowers users to design, order, and print their customized
                  calendars through a choice of print shops. Our primary
                  objective was to design a user-friendly tool that offers a
                  seamless and intuitive interface while simultaneously
                  providing an extensive array of customization options.
                </p>
              </Col>
              <Col
                sm={{ size: 4, offset: 2 }}
                className="p-0 section-text float-left"
              >
                <h4>Scope</h4>
                <p className="gray">
                  User Experience Desing / User Interface Design / Product
                  Design
                </p>
                <h4>Date</h4>
                <p className="gray">2017</p>
                <h4>Client & industry</h4>
                <p className="gray">Printilo / Printing</p>
              </Col>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container
        className="imagery-content last-imagery"
        style={{ background: "#fff", marginBottom: 30 }}
        fluid
        tag="section"
      >
        <div className="work-image">
          <StaticImage
            src="../images/calendar/calendar_4.jpg"
            alt="web_1"
            width={1600}
            quality={100}
            formats={["auto", "webp"]}
          />
        </div>
      </Container>
    </Row>
    <WipeScreen />
    <PageFooter project="printilo" />
  </>
)

SecondPage.Layout = Layout
export default SecondPage
